import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { IFilterValue } from '../../models';


@Component({
    selector: 'btn-group-switch',
    templateUrl: './btn-group-switch.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule]
})
export class BtnGroupSwitchComponent {
	@Input() selected: any;
	@Input() btnClass = '';
	@Input() buttons: IFilterValue[] = [];

	@Output() valueChange = new EventEmitter();
}
